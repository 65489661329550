/* --------------------------------------
/*  Import
/* -------------------------------------- */
/* --------------------------------------
/*  Mixins
/* -------------------------------------- */
/*** Media Queries ***/
/* --------------------------------------
/*  Less Variables
/* -------------------------------------- */
/* ---------------------
/* Mixins
/* --------------------- */
/* ---------------------
/* Colours
/* --------------------- */
/* --------------------------------------
/*  Font Sizes
/* -------------------------------------*/
@import url(../vendor/bootstrap/css/bootstrap.min.css);
.fz-11 {
  font-size: 11px;
}

.fz-12 {
  font-size: 12px;
}

.fz-13 {
  font-size: 13px;
}

.fz-15 {
  font-size: 15px;
}

.fz-17 {
  font-size: 17px;
}

.fz-20 {
  font-size: 20px;
}

.fz-22 {
  font-size: 22px;
}

.fz-30 {
  font-size: 30px;
}

/* --------------------------------------
/*  Font Weights
/* -------------------------------------*/
.fw-thin {
  font-weight: 100;
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-bold {
  font-weight: 700;
}

.text-colour__rn-black {
  color: #1F191A;
}

.background-colour__rn-black {
  background-color: #1F191A;
}

.text-colour__rn-black-900 {
  color: #373333;
}

.background-colour__rn-black-900 {
  background-color: #373333;
}

.text-colour__rn-black-800 {
  color: #4F4B4C;
}

.background-colour__rn-black-800 {
  background-color: #4F4B4C;
}

.text-colour__rn-black-700 {
  color: #666263;
}

.background-colour__rn-black-700 {
  background-color: #666263;
}

.text-colour__rn-black-600 {
  color: #7D7A7A;
}

.background-colour__rn-black-600 {
  background-color: #7D7A7A;
}

.text-colour__rn-black-500 {
  color: #939091;
}

.background-colour__rn-black-500 {
  background-color: #939091;
}

.text-colour__rn-black-400 {
  color: #A9A8A8;
}

.background-colour__rn-black-400 {
  background-color: #A9A8A8;
}

.text-colour__rn-black-300 {
  color: #BFBDBD;
}

.background-colour__rn-black-300 {
  background-color: #BFBDBD;
}

.text-colour__rn-black-200 {
  color: #D5D3D3;
}

.background-colour__rn-black-200 {
  background-color: #D5D3D3;
}

.text-colour__rn-black-100 {
  color: #EAEAEA;
}

.background-colour__rn-black-100 {
  background-color: #EAEAEA;
}

.text-colour__rn-aqua {
  color: #3C97A7;
}

.background-colour__rn-aqua {
  background-color: #3C97A7;
}

.text-colour__rn-aqua-900 {
  color: #00ABB9;
}

.background-colour__rn-aqua-900 {
  background-color: #00ABB9;
}

.text-colour__rn-aqua-800 {
  color: #00B4C1;
}

.background-colour__rn-aqua-800 {
  background-color: #00B4C1;
}

.text-colour__rn-aqua-700 {
  color: #00BDC9;
}

.background-colour__rn-aqua-700 {
  background-color: #00BDC9;
}

.text-colour__rn-aqua-600 {
  color: #00C7D1;
}

.background-colour__rn-aqua-600 {
  background-color: #00C7D1;
}

.text-colour__rn-aqua-500 {
  color: #42D0D8;
}

.background-colour__rn-aqua-500 {
  background-color: #42D0D8;
}

.text-colour__rn-aqua-400 {
  color: #74DAE0;
}

.background-colour__rn-aqua-400 {
  background-color: #74DAE0;
}

.text-colour__rn-aqua-300 {
  color: #9BE3E8;
}

.background-colour__rn-aqua-300 {
  background-color: #9BE3E8;
}

.text-colour__rn-aqua-200 {
  color: #BFEDF0;
}

.background-colour__rn-aqua-200 {
  background-color: #BFEDF0;
}

.text-colour__rn-aqua-100 {
  color: #DFF5F7;
}

.background-colour__rn-aqua-100 {
  background-color: #DFF5F7;
}

.text-colour__rn-darkgrey {
  color: #52707D;
}

.background-colour__rn-darkgrey {
  background-color: #52707D;
}

.text-colour__rn-darkgrey-900 {
  color: #647E8B;
}

.background-colour__rn-darkgrey-900 {
  background-color: #647E8B;
}

.text-colour__rn-darkgrey-800 {
  color: #778D98;
}

.background-colour__rn-darkgrey-800 {
  background-color: #778D98;
}

.text-colour__rn-darkgrey-700 {
  color: #899BA5;
}

.background-colour__rn-darkgrey-700 {
  background-color: #899BA5;
}

.text-colour__rn-darkgrey-600 {
  color: #99ABB2;
}

.background-colour__rn-darkgrey-600 {
  background-color: #99ABB2;
}

.text-colour__rn-darkgrey-500 {
  color: #ACB8BF;
}

.background-colour__rn-darkgrey-500 {
  background-color: #ACB8BF;
}

.text-colour__rn-darkgrey-400 {
  color: #BDC6CB;
}

.background-colour__rn-darkgrey-400 {
  background-color: #BDC6CB;
}

.text-colour__rn-darkgrey-300 {
  color: #CDD5D9;
}

.background-colour__rn-darkgrey-300 {
  background-color: #CDD5D9;
}

.text-colour__rn-darkgrey-200 {
  color: #DDE3E6;
}

.background-colour__rn-darkgrey-200 {
  background-color: #DDE3E6;
}

.text-colour__rn-darkgrey-100 {
  color: #EFF1F2;
}

.background-colour__rn-darkgrey-100 {
  background-color: #EFF1F2;
}

.text-colour__rn-lightgrey {
  color: #E7EDEE;
}

.background-colour__rn-lightgrey {
  background-color: #E7EDEE;
}

.text-colour__rn-lightgrey-900 {
  color: #C3D7D9;
}

.background-colour__rn-lightgrey-900 {
  background-color: #C3D7D9;
}

.text-colour__rn-lightgrey-800 {
  color: #CADBDE;
}

.background-colour__rn-lightgrey-800 {
  background-color: #CADBDE;
}

.text-colour__rn-lightgrey-700 {
  color: #D1E0E2;
}

.background-colour__rn-lightgrey-700 {
  background-color: #D1E0E2;
}

.text-colour__rn-lightgrey-600 {
  color: #D7E4E6;
}

.background-colour__rn-lightgrey-600 {
  background-color: #D7E4E6;
}

.text-colour__rn-lightgrey-500 {
  color: #DEE9EA;
}

.background-colour__rn-lightgrey-500 {
  background-color: #DEE9EA;
}

.text-colour__rn-lightgrey-400 {
  color: #E5EEEF;
}

.background-colour__rn-lightgrey-400 {
  background-color: #E5EEEF;
}

.text-colour__rn-lightgrey-300 {
  color: #ECF1F2;
}

.background-colour__rn-lightgrey-300 {
  background-color: #ECF1F2;
}

.text-colour__rn-lightgrey-200 {
  color: #F2F6F6;
}

.background-colour__rn-lightgrey-200 {
  background-color: #F2F6F6;
}

.text-colour__rn-lightgrey-100 {
  color: #F9FAFB;
}

.background-colour__rn-lightgrey-100 {
  background-color: #F9FAFB;
}

.text-colour__rn-navy {
  color: #102156;
}

.background-colour__rn-navy {
  background-color: #102156;
}

.text-colour__rn-navy-900 {
  color: #00376F;
}

.background-colour__rn-navy-900 {
  background-color: #00376F;
}

.text-colour__rn-navy-800 {
  color: #194F80;
}

.background-colour__rn-navy-800 {
  background-color: #194F80;
}

.text-colour__rn-navy-700 {
  color: #3D6691;
}

.background-colour__rn-navy-700 {
  background-color: #3D6691;
}

.text-colour__rn-navy-600 {
  color: #5B7DA1;
}

.background-colour__rn-navy-600 {
  background-color: #5B7DA1;
}

.text-colour__rn-navy-500 {
  color: #7992B1;
}

.background-colour__rn-navy-500 {
  background-color: #7992B1;
}

.text-colour__rn-navy-400 {
  color: #94A9C0;
}

.background-colour__rn-navy-400 {
  background-color: #94A9C0;
}

.text-colour__rn-navy-300 {
  color: #AFBED0;
}

.background-colour__rn-navy-300 {
  background-color: #AFBED0;
}

.text-colour__rn-navy-200 {
  color: #CAD4E0;
}

.background-colour__rn-navy-200 {
  background-color: #CAD4E0;
}

.text-colour__rn-navy-100 {
  color: #E4EAF0;
}

.background-colour__rn-navy-100 {
  background-color: #E4EAF0;
}

/* ---------------------
/* Borders
/* --------------------- */
/* ---------------------
/* Padding / Margin
/* --------------------- */
/* ---------------------
/* Typography
/* --------------------- */
.ff-Roboto {
  font-family: Roboto;
}

.ff-Asap {
  font-family: Asap;
}

/* --------------------------------------
/*  Helpers
/* -------------------------------------- */
img {
  display: block;
  max-width: 100%;
  height: auto;
}

.google-map {
  min-height: 300px;
  height: 400px;
}

.padding-section {
  padding: 50px 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-top {
  margin-top: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.spacer {
  height: 1em;
}

.background__primary-colour {
  background: #00AEEF;
}

.background__secondary-colour {
  background: #06446A;
}

.background__third-color {
  background: #F58025;
}

.background__white {
  background: rgba(255, 255, 255, 0.85);
}

.background__light-blue {
  background: #E5EEF0;
}

.text-colour__primary-colour {
  color: #00AEEF;
}

.text-colour__secondary-colour {
  color: #06446A;
}

.text-colour__third-color {
  color: #F58025;
}

.text-colour__white {
  color: #fff;
}

.text-uppercase {
  text-transform: uppercase;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.tiny {
  width: 5px;
  white-space: nowrap;
  text-align: center;
}

/* -------------------------------------- */
/*  Buttons
/* -------------------------------------- */
/* --------------------------------------
/*  Mixins
/* -------------------------------------- */
/*** Media Queries ***/
/* --------------------------------------
/*  Less Variables
/* -------------------------------------- */
/* ---------------------
/* Mixins
/* --------------------- */
body.security-page {
  background-image: url("../images/security-bg.jpg");
}

body {
  background: #F5F5F5 top center no-repeat;
  text-align: center;
}

body.security-page {
  background-size: cover;
}

.login-logo {
  max-width: 350px;
  display: block;
  margin: 1.5em auto 0;
}

.back-to-website, .back-to-login {
  color: #333;
}

.back-to-website:hover, .back-to-website:focus, .back-to-website:active, .back-to-login:hover, .back-to-login:focus, .back-to-login:active {
  color: #333;
}

.security-form {
  max-width: 350px;
  margin: 30px auto;
  background: #fff;
  padding: 2em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  /*** Email Field ***/
}

.security-form h1 {
  padding: 0;
  margin: 0 0 1em;
  font-size: 17px;
  font-weight: 700;
  color: #333;
}

.security-form i.larger.fa {
  display: inline-block;
  width: 150px;
  height: 150px;
  background: #f5f5f5;
  color: #939091;
  font-size: 55px;
  padding: 0.8em 0;
  margin-bottom: 0.3em;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  text-shadow: inset 0px 1px 3px rgba(255, 255, 255, 0.9);
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  -ms-border-radius: 150px;
  -o-border-radius: 150px;
  border-radius: 150px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
}

.security-form .no-margin {
  margin: 0;
}

.security-form .form-group input {
  height: auto;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 1em;
  box-sizing: border-box;
  font-size: 14px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.security-form .btn-primary {
  padding: 1em;
  background: #1E3063;
  font-weight: 500;
  border-color: #121d3c;
  margin-bottom: 1em;
}

.security-form .btn-primary:hover, .security-form .btn-primary:focus, .security-form .btn-primary:active {
  background: #121d3c;
  border-color: #121d3c;
}

.security-form .smaller-text {
  color: #939091;
  padding: 0;
  margin: 0;
  display: block;
  font-size: 11px;
}

.security-form .smaller-text.forgot-password {
  margin: -2px 0 -10px;
}

.security-form .email-field {
  background: #F3F6F7;
  padding: 2em 0;
  margin: 0 -2em 1em;
}
