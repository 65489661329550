
//@import 'variables';

/* --------------------------------------
/*  Mixins
/* -------------------------------------- */

//
// BOOTSTRAP MIXINS
//

@mixin bootstrap-danger-container {
  border: 1px solid #ebccd1;
  background-color: #f2dede;
  color: #a94442;
}


@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

/*** Media Queries ***/

// All Elements
@mixin mq_lg-down {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin mq_md-down {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin mq_sm-down {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin mq_xs-down {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin mq_xxs-down {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin mq_xs-up {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin columns($amount) {
  -webkit-columns: $amount; /* Chrome, Safari, Opera */
  -moz-columns: $amount; /* Firefox */
  columns: $amount;
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  ::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  ::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
    background: $background-color;
  }
}

// REM information
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// Border
@mixin border($t: 0, $r: 0, $b: 0, $l: 0, $color: $border-colour, $type: solid) {
  border: $type $color;
  border-width: $t+px $r+px $b+px $l+px;
}

// No Text Decoration
@mixin no-td() {
  text-decoration: none;
  &:hover, &:focus, &:active, &a, &a:hover, &a:focus, a {
    text-decoration: none;
  }
}

// Background With Hover
@mixin background-with-hover($bg-colour, $shade: dark, $percent: 10%) {
  background: $bg-colour;
  @include transition(background-color 0.5s);

  &:hover, &:focus {
    @if $shade == dark {
      background: darken($bg-colour, $percent);
    }
    @if $shade == light {
      background: lighten($bg-colour, $percent);
    }
  }
}

// Link Colours
@mixin link-colours ($color) {
  a, &a {
    color: $color;
    &:hover, &:focus {
      text-decoration: underline;
      color: darken($color, 10%);
    }
  }
}

// Text Mixin
@mixin text($ff:$primary-font, $fs:calculateRem(17px), $c:$font-colour, $lh:$main-line-height, $fw:$main-font-weight, $tt:none, $ls:0.0em) {
  font-family: $ff;
  font-size: $fs;
  color: $c;
  line-height: $lh;
  font-weight: $fw;
  text-transform: $tt;
  letter-spacing: $ls;
}

// All Elements
@mixin all-type-elements {
  h1, h2, h3,
  h4, h5, h6,
  p, a, span,
  ul, ol, li,
  input, button {
    @content;
  }
}

// All Elements
@mixin all-heading-elements {
  h1, h2, h3,
  h4, h5, h6 {
    @content;
  }
}

// All Elements
@mixin all-bootstap-divs {
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9
  {
    @content;
  }
}

/* --------------------------------------
/*  Less Variables
/* -------------------------------------- */

@mixin gradient ($startColor: #eee, $endColor: white) {
  background-color: $startColor;
  background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
  background: -webkit-linear-gradient(top, $startColor, $endColor);
  background: -moz-linear-gradient(top, $startColor, $endColor);
  background: -ms-linear-gradient(top, $startColor, $endColor);
  background: -o-linear-gradient(top, $startColor, $endColor);
}
@mixin horizontal-gradient ($startColor: #eee, $endColor: white) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(left, $startColor, $endColor);
  background-image: -moz-linear-gradient(left, $startColor, $endColor);
  background-image: -ms-linear-gradient(left, $startColor, $endColor);
  background-image: -o-linear-gradient(left, $startColor, $endColor);
}

/* ---------------------
/* Mixins
/* --------------------- */

@mixin clearfix () {
  &:after {
    display: table;
    content: " ";
  }

  &:after {
    clear: both;
  }
}

@mixin rounded-corners ($radius: 3px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow ($string: 1px 8px 10px 0px rgba(54, 50, 50, 0.2)) {
  -webkit-box-shadow: $string;
  -moz-box-shadow:    $string;
  box-shadow:         $string;
}

@mixin transition($transition: all 0.5s) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin transition-delay($delay: 2s) {
  -webkit-transition-delay: $delay; /* Safari */
  transition-delay: $delay;
}

@mixin opacity ($opacity: 0.5) {
  -webkit-opacity: 	$opacity;
  -moz-opacity: 	$opacity;
  opacity: 		$opacity;
}

@mixin box-shadow ($string) {
  -webkit-box-shadow: $string;
  -moz-box-shadow:    $string;
  box-shadow:         $string;
}

@mixin text-shadow ($string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
  text-shadow: $string;
}

@mixin text($ff: $primary-font, $fs: $main-font-size, $c: $font-colour, $lh: $main-line-height, $fw: normal, $tt: none, $ls: 0px) {
  font-family: $ff;
  font-size: $fs;
  color: $c;
  line-height: $lh;
  font-weight: $fw;
  text-transform: $tt;
  letter-spacing: $ls;
}

@mixin transform($string){
  -webkit-transform: $string;
  -moz-transform: 	 $string;
  -ms-transform:     $string;
  -o-transform: 	 $string;
}
@mixin scale ($factor) {
  -webkit-transform: scale($factor);
  -moz-transform: 	 scale($factor);
  -ms-transform:     scale($factor);
  -o-transform: 	 scale($factor);
}
@mixin rotate ($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: 	 rotate($deg);
  -ms-transform: 	 rotate($deg);
  -o-transform: 	 rotate($deg);
}
@mixin skew ($deg, $deg2) {
  -webkit-transform:     skew($deg, $deg2);
  -moz-transform: 	     skew($deg, $deg2);
  -ms-transform: 		 skew($deg, $deg2);
  -o-transform: 		 skew($deg, $deg2);
}
@mixin translate ($x, $y:0) {
  -webkit-transform:  translate($x, $y);
  -moz-transform: 	  translate($x, $y);
  -ms-transform: 	  translate($x, $y);
  -o-transform: 	  translate($x, $y);
}
@mixin translate3d ($x, $y: 0, $z: 0) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: 	 translate3d($x, $y, $z);
  -ms-transform: 	 translate3d($x, $y, $z);
  -o-transform: 	 translate3d($x, $y, $z);
}
@mixin perspective ($value: 1000) {
  -webkit-perspective: 	$value;
  -moz-perspective: 	$value;
  -ms-perspective: 	    $value;
  perspective: 		    $value;
}
@mixin transform-origin ($x:center, $y:center) {
  -webkit-transform-origin: $x $y;
  -moz-transform-origin:    $x $y;
  -ms-transform-origin:     $x $y;
  -o-transform-origin:      $x $y;
}


// --------------------------------------------------
// Flexbox LESS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
// flex or inline-flex
@mixin flex-display($display: flex) {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox; // IE10 uses -ms-flexbox
  display: -ms-flex; // IE11
  display: $display;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($columns: initial) {
  -webkit-flex: $columns;
  -moz-flex: $columns;
  -ms-flex: $columns;
  flex: $columns;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction: row) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap: nowrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin flex-order($order: 0) {
  -webkit-order: $order;
  -moz-order: $order;
  -ms-order: $order;
  order: $order;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow: 0) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

// Flex shr
// - applies to: flex itemsink factor
// <number>
@mixin flex-shrink($shrink: 1) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width: auto) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify: flex-start) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align: stretch) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align: stretch) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align: auto) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}