/* --------------------------------------
/*  Import
/* -------------------------------------- */

@import 'mixins';

/* ---------------------
/* Colours
/* --------------------- */

$primary-colour: #00AEEF;
$secondary-colour: #06446A;
$third-colour: #F58025;
$light-gray: #F3F6F7;

$font-colour: #333;
$background: #fff;
$light-blue: #E5EEF0;

// Grays
$gray-darker:  lighten(#000, 13.5%); // #222
$gray-dark:    lighten(#000, 20%);   // #333
$gray:         lighten(#000, 33.5%); // #555
$gray-light:   lighten(#000, 46.7%); // #777
$gray-lighter: lighten(#000, 93.5%); // #eee

/* --------------------------------------
/*  Font Sizes
/* -------------------------------------*/
$fz-11: 11px;
$fz-12: 12px;
// This is a change late in the game.
$fz-13: 14px;
$fz-15: 15px;
$fz-17: 17px;
$fz-20: 20px;
$fz-22: 22px;
$fz-30: 30px;

$font-sizes: 11,12,13,15,17,20,22,30;
@each $size in $font-sizes {
  .#{"fz-" + $size} {
    font-size:  #{$size}px;
  }
}

/* --------------------------------------
/*  Font Weights
/* -------------------------------------*/
$fw-thin: 100;
$fw-light: 300;
$fw-normal: 400;
$fw-medium: 500;
$fw-bold: 700;

$font-weight: (
  // Black
        'thin' : $fw-thin,
        'light' : $fw-light,
        'normal' : $fw-normal,
        'medium' : $fw-medium,
        'bold' : $fw-bold
);
@each $label, $weight in $font-weight {
  .#{"fw-" + $label} {
    font-weight:  #{$weight};
  }
}

$sp-blue: #1E3063;

// NAVY
$rn-navy: #102156;
$rn-navy-900: #00376F;
$rn-navy-800: #194F80;
$rn-navy-700: #3D6691;
$rn-navy-600: #5B7DA1;
$rn-navy-500: #7992B1;
$rn-navy-400: #94A9C0;
$rn-navy-300: #AFBED0;
$rn-navy-200: #CAD4E0;
$rn-navy-100: #E4EAF0;

// LIGHT GREEN
$rn-lightgreen: #98ce3c;

// DARK GREEN
$rn-darkgreen: #58906d;
$rn-darkgreen-600: darken($rn-darkgreen, 5%);

// LIGHT GREY
$rn-lightgrey: #E7EDEE;
$rn-lightgrey-900: #C3D7D9;
$rn-lightgrey-800: #CADBDE;
$rn-lightgrey-700: #D1E0E2;
$rn-lightgrey-600: #D7E4E6;
$rn-lightgrey-500: #DEE9EA;
$rn-lightgrey-400: #E5EEEF;
$rn-lightgrey-300: #ECF1F2;
$rn-lightgrey-200: #F2F6F6;
$rn-lightgrey-100: #F9FAFB;

// DARK GREY
$rn-darkgrey: #52707D;
$rn-darkgrey-900: #647E8B;
$rn-darkgrey-800: #778D98;
$rn-darkgrey-700: #899BA5;
$rn-darkgrey-600: #99ABB2;
$rn-darkgrey-500: #ACB8BF;
$rn-darkgrey-400: #BDC6CB;
$rn-darkgrey-300: #CDD5D9;
$rn-darkgrey-200: #DDE3E6;
$rn-darkgrey-100: #EFF1F2;

// AQUA
$rn-aqua: #3C97A7;
$rn-aqua-900: #00ABB9;
$rn-aqua-800: #00B4C1;
$rn-aqua-700: #00BDC9;
$rn-aqua-600: #00C7D1;
$rn-aqua-500: #42D0D8;
$rn-aqua-400: #74DAE0;
$rn-aqua-300: #9BE3E8;
$rn-aqua-200: #BFEDF0;
$rn-aqua-100: #DFF5F7;

// BLACK
$rn-black: #1F191A;
$rn-black-900: #373333;
$rn-black-850: #433F40;
$rn-black-800: #4F4B4C;
$rn-black-700: #666263;
$rn-black-600: #7D7A7A;
$rn-black-500: #939091;
$rn-black-400: #A9A8A8;
$rn-black-300: #BFBDBD;
$rn-black-200: #D5D3D3;
$rn-black-100: #EAEAEA;

// Helper Classes
$colour-list: (
  // Black
      'rn-black' : $rn-black,
      'rn-black-900' : $rn-black-900,
      'rn-black-800' : $rn-black-800,
      'rn-black-700' : $rn-black-700,
      'rn-black-600' : $rn-black-600,
      'rn-black-500' : $rn-black-500,
      'rn-black-400' : $rn-black-400,
      'rn-black-300' : $rn-black-300,
      'rn-black-200' : $rn-black-200,
      'rn-black-100' : $rn-black-100,
  // Aqua
      'rn-aqua' : $rn-aqua,
      'rn-aqua-900' : $rn-aqua-900,
      'rn-aqua-800' : $rn-aqua-800,
      'rn-aqua-700' : $rn-aqua-700,
      'rn-aqua-600' : $rn-aqua-600,
      'rn-aqua-500' : $rn-aqua-500,
      'rn-aqua-400' : $rn-aqua-400,
      'rn-aqua-300' : $rn-aqua-300,
      'rn-aqua-200' : $rn-aqua-200,
      'rn-aqua-100' : $rn-aqua-100,
  // Dark Grey
      'rn-darkgrey' :  $rn-darkgrey,
      'rn-darkgrey-900' :  $rn-darkgrey-900,
      'rn-darkgrey-800' :  $rn-darkgrey-800,
      'rn-darkgrey-700' :  $rn-darkgrey-700,
      'rn-darkgrey-600' :  $rn-darkgrey-600,
      'rn-darkgrey-500' :  $rn-darkgrey-500,
      'rn-darkgrey-400' :  $rn-darkgrey-400,
      'rn-darkgrey-300' :  $rn-darkgrey-300,
      'rn-darkgrey-200' :  $rn-darkgrey-200,
      'rn-darkgrey-100' :  $rn-darkgrey-100,
  // Light Grey
      'rn-lightgrey' :  $rn-lightgrey,
      'rn-lightgrey-900' :  $rn-lightgrey-900,
      'rn-lightgrey-800' :  $rn-lightgrey-800,
      'rn-lightgrey-700' :  $rn-lightgrey-700,
      'rn-lightgrey-600' :  $rn-lightgrey-600,
      'rn-lightgrey-500' :  $rn-lightgrey-500,
      'rn-lightgrey-400' :  $rn-lightgrey-400,
      'rn-lightgrey-300' :  $rn-lightgrey-300,
      'rn-lightgrey-200' :  $rn-lightgrey-200,
      'rn-lightgrey-100' :  $rn-lightgrey-100,
  // Navy
      'rn-navy' : $rn-navy,
      'rn-navy-900' : $rn-navy-900,
      'rn-navy-800' : $rn-navy-800,
      'rn-navy-700' : $rn-navy-700,
      'rn-navy-600' : $rn-navy-600,
      'rn-navy-500' : $rn-navy-500, 
      'rn-navy-400' : $rn-navy-400,
      'rn-navy-300' : $rn-navy-300,
      'rn-navy-200' : $rn-navy-200,
      'rn-navy-100' : $rn-navy-100

);
@each $label, $property in $colour-list {
  .#{"text-colour__" + $label} {
    color: #{$property};
  }
  .#{"background-colour__" + $label} {
    background-color: #{$property};
  }
}


//
//$base-gi-colours: gi-navy, gi-lightgrey, gi-darkgrey, gi-aqua, gi-black;
//
//// Helper Classes
////@each $basecolour in $base-gi-colours {
//  @for $i from 1 through 9 {
//    .#{"text-colour__gi-navy-" + $i + "00"} {
//      color: gi-navy-#{$i}00;
//    }
//    //.#{"background-colour__" + $label} {
//    //  background-color: #{$property};
//    //}
//  }
////}
//


/* ---------------------
/* Borders
/* --------------------- */

$border-colour: #DDDDDD;
// See mixins for border

/* ---------------------
/* Padding / Margin
/* --------------------- */

$section-padding: 50px;
$text-padding: 1em;

/* ---------------------
/* Typography
/* --------------------- */

$roboto: 'Roboto';
$asap: 'Asap';

$font-families: (
  // Black
        'Roboto' : $roboto,
        'Asap' : $asap
);
@each $label, $family in $font-families {
  .#{"ff-" + $label} {
    font-family: #{$family};
  }
}


$primary-font:	$roboto, Arial, Helvetica, San-serif;
$secondary-font: $asap, Arial, Helvetica, San-serif;
$main-line-height: 1.4;
$main-font-size: $fz-13;
$main-font-weight: $fw-light;

/* --------------------------------------
/*  Helpers
/* -------------------------------------- */

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.google-map {
  min-height: 300px;
  height: 400px;
}

// Helpers
.padding-section { padding: $section-padding 0; }
.no-margin-bottom { margin-bottom: 0; }
.no-margin-top { margin-top: 0; }
.cursor-pointer { cursor: pointer; };
.spacer { height: 1em; }


// EACH LOOP
$colours-list: (primary-colour, $primary-colour), (secondary-colour, $secondary-colour), (third-color, $third-colour), (white, rgba(255,255,255,0.85)), (light-blue, #E5EEF0);
@each $label, $colour in $colours-list {
  $i: index($colours-list, $colour);
  .background__#{$label} {
    background: $colour;
  }
}

// EACH LOOP
$colours-list: (primary-colour, $primary-colour), (secondary-colour, $secondary-colour), (third-color, $third-colour), (white, #fff);
@each $label, $colour in $colours-list {
  $i: index($colours-list, $colour);
  .text-colour__#{$label} {
    color: $colour;
  }
}

.text-uppercase {  text-transform: uppercase; }
.block {display: block;}
.inline-block {display: inline-block;}
.tiny {
    width: 5px;
    white-space: nowrap;
    text-align: center;
}

/* -------------------------------------- */
/*  Buttons
/* -------------------------------------- */
